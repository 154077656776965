import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Flex,
  Text,
  Button,
  PseudoBox,
  BreadcrumbItem,
  BreadcrumbLink,
  Breadcrumb,
  Icon,
  Alert,
  AlertIcon,
  Grid,
  Stack,
  Image,
  Tag,
  TagLabel,
  TagCloseButton,
  Divider,
  Progress,
  Avatar,
  Badge,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/core";
import AnimateHeight from "react-animate-height";
import numeral from "numeral";
import { octubre } from "../data/octubre_file";
import { db } from "../data/db_file";
import Countdown from "react-countdown";
import moment from "moment";
import { useTable, useColumnOrder } from "react-table";
import { useParams } from "react-router-dom";

const payment_type_msg = {
  batch_payment: "Batch Payment",
  regular_payment: "Regular Payment",
};
const credit_card_img = {
  master: require("../images/cards/master.png"),
  visa: require("../images/cards/visa.png"),
  amex: require("../images/cards/amex.png"),
};

const ShowBatch = (props) => {
  let { id } = useParams();
  const [batch, setBatch] = useState(id);
  const [result, setResult] = useState([]);
  const [filterDb, setFilterDb] = useState(db.filter((d) => d.batch_id == id));
  const [counter, setCounter] = useState(0);
  const [running, setRunning] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const data = React.useMemo(() => [...filterDb], []);

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id", // accessor is the "key" in the data
      },
      {
        Header: "Nombre",
        accessor: "first_name",
      },
      {
        Header: "Apellido",
        accessor: "last_name",
      },
      {
        Header: "ID Empresa",
        accessor: "external_id",
      },

      {
        Header: "Reference Empresa",
        accessor: "partner_reference",
      },
      {
        Header: "Descripción",
        accessor: "description",
      },
      {
        Header: "Monto",
        accessor: "transaction_amount",
      },
      {
        Header: "Int",
        accessor: "intento",
      },
      {
        Header: "Estado",
        accessor: "status",
      },
    ],
    []
  );

  useEffect(() => {
    if (batch == 56) {
      toogle();
    }
  }, [batch]);

  const getRandomStatus = () => {
    let number = Math.floor(Math.random() * (5 - 1 + 1)) + 1;
    let options = [
      "approved",
      "approved",
      "approved",
      "rejected",
      "approved",
      "approved",
    ];
    return options[number];
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setColumnOrder,
  } = useTable({
    columns,
    data,
    initialState: {
      pageIndex: 0,
      hiddenColumns: running ? ["email", "description"] : [],
    },
  });

  const toogle = () => {
    onClose();
    setRunning(true);
    let i = counter;
    let res = result;
    setInterval(() => {
      res[i] = getRandomStatus();
      setCounter(i + 1);
      setResult(res);
      i += 1;
    }, 1200);
  };

  return (
    <>
      {/* {toHome ? <Redirect to="/home" /> : null} */}
      <Flex
        w="full"
        direction="column"
        minH="100vh"
        align="center"
        justify="flex-start"
        className={"access-back"}
        bg={"white"}
        color={"gray.800"}
      >
        <Box
          width={[
            "100%", // base
            "100%", // 480px upwards
            "100%", // 768px upwards
            "70%", // 992px upwards
          ]}
          padding={5}
        >
          <Breadcrumb
            spacing="8px"
            separator={<Icon color="gray.300" name="chevron-right" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink href="/">Home</BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem>
              <BreadcrumbLink href="/about">Batch</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink href="/batch/new">Batch#{batch}</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <Alert mt={4} status="info">
            <AlertIcon />
            Este es un sitio de prueba, no se ejecutará ninguna acción sobre
            ventas ni configuraciones.
          </Alert>
          {/* START */}
          <Flex
            w="full"
            alignItems="center"
            justifyContent="flex-start"
            mb={4}
            mt={4}
          >
            <Flex
              rounded="full"
              h="64px"
              w="64px"
              bg="teal.600"
              mx={2}
              justifyContent="center"
              alignItems="center"
            >
              <Text color={"white"} fontSize="18px">
                #
              </Text>
              <Text
                // mr={4}
                fontWeight="semibold"
                color={"white"}
                fontSize="24px"
              >
                {batch}
              </Text>
            </Flex>
            <Heading pr={6}> Batch</Heading>
          </Flex>
          <Divider />

          <Flex w="full" alignItems="center" justifyContent="flex-start" my={4}>
            <Flex
              w="full"
              alignItems="flex-start"
              direction="column"
              justifyContent="center"
            >
              <Flex alignItems="center" justifyContent="flex-start">
                <Heading px={4} as="h3" size="xs">
                  BATCH ID:
                </Heading>
                <Text color="gray.800" fontSize="md">
                  {batch}
                </Text>
              </Flex>
              {/*  */}

              <Flex alignItems="center" justifyContent="flex-start">
                <Heading px={4} as="h3" size="xs">
                  FECHA CREACIÓN:
                </Heading>
                <Text color="gray.800" fontSize="md">
                  {moment(new Date()).format("DD MMM")}
                </Text>
              </Flex>
              {/*  */}

              <Flex alignItems="center" justifyContent="flex-start">
                <Heading px={4} as="h3" size="xs">
                  FECHA FINALIZACIÓN:
                </Heading>
                <Text color="gray.800" fontSize="md">
                  {moment(new Date()).add(10, "days").format("DD MMM")}
                </Text>
              </Flex>
              {/*  */}

              <Flex alignItems="center" justifyContent="flex-start">
                <Heading px={4} as="h3" size="xs">
                  PRÓXIMA EJECUCIÓN:
                </Heading>
                <Text color="gray.800" fontSize="md">
                  64
                </Text>
              </Flex>
              {/*  */}
              <Flex alignItems="center" justifyContent="flex-start">
                <Heading px={4} as="h3" size="xs">
                  TOTAL PAGOS:
                </Heading>
                <Text color="gray.800" fontSize="md">
                  {filterDb.length}
                </Text>
              </Flex>
            </Flex>
            <Button variantColor="orange" size="lg" onClick={onOpen}>
              BAJAR BATCH
            </Button>
          </Flex>

          <Divider />

          <Flex
            alignItems="center"
            justifyContent="center"
            direction="column"
            my={4}
            mb={4}
          >
            <Flex alignItems="center" justifyContent="center">
              <Icon name="check" size="16px" />
              <Heading mx={3} as="h3" size="lg">
                {" "}
                DETALLE DE BATCH
              </Heading>
            </Flex>

            <Text fontSize="sm">Lista de los intentos de pagos del batch</Text>
            <Flex w="full" pt={6} alignItems="center" justifyContent="center">
              <Box
                as="table"
                border={1}
                borderStyle="solid"
                borderTopLeftRadius={3}
                borderSpacing={0}
                {...getTableProps()}
              >
                <Box as="thead" rounded="lg">
                  {headerGroups.map((headerGroup) => (
                    <Box
                      as="tr"
                      rounded="lg"
                      {...headerGroup.getHeaderGroupProps()}
                    >
                      {headerGroup.headers.map((column) => (
                        <Box
                          as="th"
                          m={0}
                          p="0.5rem"
                          borderBottom={1}
                          borderBottomStyle="solid"
                          borderRight={1}
                          bg={"teal.400"}
                          borderRightStyle="solid"
                          {...column.getHeaderProps()}
                        >
                          {column.render("Header")}
                        </Box>
                      ))}
                    </Box>
                  ))}
                </Box>
                <Box as="tbody" {...getTableBodyProps()}>
                  {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <Box as="tr" {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          console.log();
                          if (cell.column["Header"] == "Int") {
                            return (
                              <Box
                                as="td"
                                m={0}
                                p="0.5rem"
                                borderBottom={1}
                                borderBottomStyle="solid"
                                borderRight={1}
                                borderRightStyle="solid"
                                {...cell.getCellProps()}
                              >
                                1
                              </Box>
                            );
                          } else if (cell.column["Header"] == "Estado") {
                            return (
                              <Box
                                as="td"
                                m={0}
                                p="0.5rem"
                                borderBottom={1}
                                borderBottomStyle="solid"
                                borderRight={1}
                                borderRightStyle="solid"
                                textAlign="center"
                                {...cell.getCellProps()}
                              >
                                {result[i]
                                  ? status_tag(result[i])
                                  : status_tag(
                                      batch != 56
                                        ? cell.render("Cell").props.cell.value
                                        : "pending"
                                    )}
                              </Box>
                            );
                          } else if (cell.column["Header"] == "Monto") {
                            return (
                              <Box
                                as="td"
                                m={0}
                                p="0.5rem"
                                borderBottom={1}
                                borderBottomStyle="solid"
                                borderRight={1}
                                borderRightStyle="solid"
                                {...cell.getCellProps()}
                              >
                                {numeral(
                                  cell.render("Cell").props.cell.value
                                ).format("$0,0.00")}
                              </Box>
                            );
                          } else {
                            return (
                              <Box
                                as="td"
                                m={0}
                                p="0.5rem"
                                borderBottom={1}
                                borderBottomStyle="solid"
                                borderRight={1}
                                borderRightStyle="solid"
                                {...cell.getCellProps()}
                              >
                                {cell.render("Cell")}
                              </Box>
                            );
                          }
                        })}
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Flex>
          </Flex>
        </Box>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Generando reporte</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Le enviaremos a su email el reporte una vez generado
          </ModalBody>

          <ModalFooter>
            <Button variantColor="teal" mr={3} onClick={onClose}>
              OK
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ShowBatch;

const status_tag = (status) => {
  let status_msg = {
    approved: "APROBADO",
    pending: "PENDIENTE",
    rejected: "RECHAZADO",
    finalizado: "FINALIZADO",
  };
  let status_color = {
    approved: "green",
    pending: "orange",
    rejected: "red",
    finalizado: "blue",
  };
  return (
    <Tag
      size="sm"
      rounded="full"
      variant="solid"
      variantColor={status_color[status]}
    >
      <TagLabel>{status_msg[status]}</TagLabel>
    </Tag>
  );
};
