import React from "react";
import {
  Box,
  Heading,
  Flex,
  Text,
  Button,
  PseudoBox,
  BreadcrumbItem,
  BreadcrumbLink,
  Breadcrumb,
  Icon,
  Alert,
  AlertIcon,
  Grid,
  Stack,
  Image,
  Tag,
  TagLabel,
  TagCloseButton,
} from "@chakra-ui/core";
import numeral from "numeral";
import {payments} from "../data/payments_list"
const MenuItems = ({ children }) => (
  <Text mt={{ base: 4, md: 0 }} mr={6} display="block">
    {children}
  </Text>
);

const payment_type_msg = {batch_payment: "Batch Payment", regular_payment: "Regular Payment"}
const credit_card_img = { master: require("../images/cards/master.png"), visa: require("../images/cards/visa.png"), amex: require("../images/cards/amex.png"),}

const Activities = (props) => {
  const [show, setShow] = React.useState(false);
  const handleToggle = () => setShow(!show);

  return (
    <>
      {/* {toHome ? <Redirect to="/home" /> : null} */}
      <Flex
        w="full"
        direction="column"
        minH="100vh"
        align="center"
        justify="flex-start"
        className={"access-back"}
        bg={"white"}
        color={"gray.800"}
      >
        <Box
          width={[
            "100%", // base
            "100%", // 480px upwards
            "100%", // 768px upwards
            "70%", // 992px upwards
          ]}
          padding={5}
        >
          <Breadcrumb
            spacing="8px"
            separator={<Icon color="gray.300" name="chevron-right" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink href="/">Home</BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem>
              <BreadcrumbLink href="/about">Ventas</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <Alert mt={4} status="info">
            <AlertIcon />
            Este es un sitio de prueba, no se ejecutará ninguna acción sobre
            ventas ni configuraciones.
          </Alert>
          {/* START */}
          <Box mb={4} mt={4} maxW="32rem">
            <Heading>Ventas</Heading>
            <Text ml={1} fontSize="sm">
              Explorá todas las ventas realizadas
            </Text>
          </Box>

          <Box
            maxW="full"
            borderColor={"gray.100"}
            rounded="lg"
            borderWidth="2px"
            overflow="hidden"
            bg={"white"}
            color={"gray.800"}
          >
            {/* line */}
            {payments.map((p) => {
              return (
                <PseudoBox
                  as="a"
                  href="#"
                  _hover={{ bg: "gray.50" }}
                  d="flex"
                  py={3}
                  alignItems="center"
                  justifyContent="center"
                  lineHeight="tight"
                  borderBottom={"1px solid #EDF2F7"}
                >
                  <Grid
                    w="full"
                    templateColumns={{
                      md: "350px auto 110px 200px",
                      sm: "auto auto auto auto",
                    }}
                    gap={3}
                  >
                    <Box pl={4}>
                      <Flex
                        w="full"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={2}
                      >
                        <Text fontWeight="semibold" fontSize="sm">
                          {p.reference}
                        </Text>
                        <Flex justifyContent="space-between">
                          <Text
                            alignItems="center"
                            color={"gray.500"}
                            fontSize="sm"
                          >
                            {p.provider_reference}
                          </Text>
                        </Flex>
                      </Flex>
                    </Box>
                    <Flex
                      w="full"
                      justifyContent="center"
                      alignItems="center"
                      direction="column"
                    >
                      <Flex
                        w="full"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Text
                          alignItems="center"
                          mr={4}
                          fontWeight="semibold"
                          color={"gray.500"}
                          fontSize="sm"
                        >
                          Tarjeta de crédito
                        </Text>
                        <Image
                          h="40px"
                          src={credit_card_img[p.payment_name]}
                          alt=""
                        />
                      </Flex>

                      <Text
                        alignItems="center"
                        color={"gray.800"}
                        fontSize="sm"
                      >
                        {payment_type_msg[p.payment_source]}
                      </Text>
                    </Flex>
                    <Flex w="full" justifyContent="center" alignItems="center">
                      {status_tag(p.status)}
                    </Flex>

                    <Flex
                      h="full"
                      pr={4}
                      justifyContent="center"
                      alignItems="flex-end"
                      direction="column"
                    >
                      <Heading as="h5" size="md" textAlign="right">
                        {numeral(p.amount).format("$0,0.00")}
                      </Heading>
                      <Text
                        alignItems="center"
                        color={"gray.400"}
                        fontSize="sm"
                        textAlign="right"
                      >
                        11/10/2020
                      </Text>
                    </Flex>
                  </Grid>
                </PseudoBox>
              );
            })}
          </Box>
        </Box>
      </Flex>
    </>
  );
};

export default Activities;

const status_tag = (status) => {
  let status_msg = {
    approved: "APROBADO",
    pending: "PENDIENTE",
    rejected: "RECHAZADO",
  };
  let status_color = { approved: "green", pending: "orange", rejected: "red" };
  return (
    <Tag
      size="sm"
      rounded="full"
      variant="solid"
      variantColor={status_color[status]}
    >
      <TagLabel>{status_msg[status]}</TagLabel>
    </Tag>
  );
};
