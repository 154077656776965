export const clients = [
    {
      reference: "302793ba-3e52-41a1-9417-1a2f97af4e74",
      amount: 8595.1,
      name: "Mitchell Sommerville",
      email: "msommervillej@geocities.com",
      dni: 35456901,
      provider_reference: 10531204563,
      date: "2019-11-17",
      status: "approved",
      payment_source: "batch_payment",
      payment_name: "visa",
      payment_type: "credit_card",
      batch_id: 56,
      cards :[
        {
          payment_name: "amex",
          last_four: 9142,
          date: "2019-11-17"
        },
        {
          payment_name: "visa",
          last_four: 1523,
          date: "2019-11-14"
        },
      ]
    },
    {
      reference: "45852527-89c3-43dc-bdc1-df7ceb59d075",
      amount: 2702.0,
      name: "Faulkner Beedie",
      email: "fbeediek@ning.com",
      dni: 39954660,
      provider_reference: 10501198036,
      date: "2020-07-11",
      status: "approved",
      payment_source: "regular_payment",
      payment_name: "master",
      payment_type: "credit_card",
      batch_id: 54,
      cards :[
        {
          payment_name: "master",
          last_four: 8394,
          date: "2019-11-17"
        },
       
      ]
    },
    {
      reference: "b9bb43af-f22d-4589-a9a7-1d95955635c7",
      amount: 6295.82,
      name: "Biddy Stive",
      email: "bstivel@godaddy.com",
      dni: 36046068,
      provider_reference: 10489594587,
      date: "2020-02-04",
      status: "approved",
      payment_source: "batch_payment",
      payment_name: "master",
      payment_type: "credit_card",
      batch_id: 55,
      cards :[
        {
          payment_name: "master",
          last_four: 2839,
          date: "2019-11-17"
        },
        {
          payment_name: "visa",
          last_four: 1230,
          date: "2019-11-14"
        },
      ]
    },
    {
      reference: "bf0077c7-af9e-4973-8a8e-32e920451eed",
      amount: 6934.36,
      name: "Arleen Arnaldy",
      email: "aarnaldym@fda.gov",
      dni: 33110108,
      provider_reference: 10520073083,
      date: "2020-01-02",
      status: "approved",
      payment_source: "batch_payment",
      payment_name: "amex",
      payment_type: "credit_card",
      batch_id: 55,
      cards :[
        {
          payment_name: "master",
          last_four: 5649,
          date: "2019-11-17"
        }, {
          payment_name: "visa",
          last_four: 5839,
          date: "2019-11-17"
        },
        {
          payment_name: "amex",
          last_four: 8390,
          date: "2019-11-12"
        },
      ]
    },
    {
      reference: "1d582d01-6102-4dad-9eca-d6521df275cd",
      amount: 8531.81,
      name: "Sutherlan Bamlett",
      email: "sbamlettn@ucsd.edu",
      dni: 34684698,
      provider_reference: 10476973983,
      date: "2020-07-28",
      status: "approved",
      payment_source: "regular_payment",
      payment_name: "visa",
      payment_type: "credit_card",
      batch_id: 55,
      cards :[
        {
          payment_name: "visa",
          last_four: 8956,
          date: "2019-11-17"
        },
        {
          payment_name: "visa",
          last_four: 3547,
          date: "2019-11-14"
        },
      ]
    },
    {
      reference: "bda2eb1f-0768-479d-bfb2-3deb7f237966",
      amount: 5314.09,
      name: "Kellina Cammis",
      email: "kcammiso@wunderground.com",
      dni: 32191413,
      provider_reference: 10456765639,
      date: "2020-04-05",
      status: "approved",
      payment_source: "batch_payment",
      payment_name: "amex",
      payment_type: "credit_card",
      batch_id: 54,
      cards :[
        {
          payment_name: "master",
          last_four: 9142,
          date: "2019-11-17"
        },
        {
          payment_name: "visa",
          last_four: 1523,
          date: "2019-11-14"
        },
      ]
    },
    {
      reference: "fd5b51f0-1bee-4136-97ad-46dd1c01ccd2",
      amount: 7787.11,
      name: "Muriel Tonkin",
      email: "mtonkinp@indiegogo.com",
      dni: 37887348,
      provider_reference: 10451395333,
      date: "2020-03-27",
      status: "approved",
      payment_source: "regular_payment",
      payment_name: "master",
      payment_type: "credit_card",
      batch_id: 55,
    },
    {
      reference: "69d4ca75-1ba2-4237-8ac1-3de7a068a4ac",
      amount: 2480.12,
      name: "Orella Scare",
      email: "oscareq@tripadvisor.com",
      dni: 37677266,
      provider_reference: 10472257024,
      date: "2019-12-29",
      status: "approved",
      payment_source: "regular_payment",
      payment_name: "master",
      payment_type: "credit_card",
      batch_id: 55,
    },
    {
      reference: "8aef7374-87d6-4c94-bd8c-a0d5f5d60316",
      amount: 6014.47,
      name: "Sylas Rhymer",
      email: "srhymerr@icio.us",
      dni: 30167185,
      provider_reference: 10458420899,
      date: "2019-11-17",
      status: "approved",
      payment_source: "regular_payment",
      payment_name: "amex",
      payment_type: "credit_card",
      batch_id: 55,
    },
    {
      reference: "2005baea-6439-4494-aba6-b68e4dbbdd86",
      amount: 5469.11,
      name: "Ulla Libbis",
      email: "ulibbiss@narod.ru",
      dni: 30755832,
      provider_reference: 10529193292,
      date: "2020-01-25",
      status: "approved",
      payment_source: "regular_payment",
      payment_name: "amex",
      payment_type: "credit_card",
      batch_id: 55,
    },
    {
      reference: "7312275c-18e0-4c5f-b0bb-d3b84417639b",
      amount: 3031.47,
      name: "Doralia Povlsen",
      email: "dpovlsent@flavors.me",
      dni: 30834567,
      provider_reference: 10486067807,
      date: "2020-07-09",
      status: "rejected",
      payment_source: "regular_payment",
      payment_name: "visa",
      payment_type: "credit_card",
      batch_id: 55,
    },
];
