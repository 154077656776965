import React from "react";
import { Box, Heading, Flex, Text, Button, Stack } from "@chakra-ui/core";

const MenuItems = ({ children }) => (
  <Text mt={{ base: 4, md: 0 }} mr={6} display="block">
    {children}
  </Text>
);

const Footer = props => {
  const [show, setShow] = React.useState(false);
  const handleToggle = () => setShow(!show);

  return (
    <Flex
      align="center"
      justify="center"
      wrap="wrap"
      marginTop="100px"
      padding="1.5rem"
      bg="#dddddd"
      color="gray.600"
      {...props}
    >
      <Text textAlign="center">
      ⚡️ FaradayHub 2020 - Todos los derechos reservados

      </Text>
    </Flex>
  );
};

export default Footer;