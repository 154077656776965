import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Flex,
  Text,
  Button,
  PseudoBox,
  BreadcrumbItem,
  BreadcrumbLink,
  Breadcrumb,
  Icon,
  Alert,
  AlertIcon,
  Grid,
  Stack,
  Image,
  Tag,
  TagLabel,
  TagCloseButton,
  Divider,
  Progress,
  Avatar,
  Badge,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormHelperText,
  FormLabel,
  InputLeftAddon,
  Select,
  Switch,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Tabs,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  IconButton,
  TagIcon,
} from "@chakra-ui/core";
import AnimateHeight from "react-animate-height";
import numeral from "numeral";
import { octubre } from "../data/octubre_file";
import Countdown from "react-countdown";
import moment from "moment";
import { useTable } from "react-table";
import ReactTags from "react-tag-autocomplete";

const payment_type_msg = {
  batch_payment: "Batch Payment",
  regular_payment: "Regular Payment",
};
const credit_card_img = {
  master: require("../images/cards/master.png"),
  visa: require("../images/cards/visa.png"),
  amex: require("../images/cards/amex.png"),
};

const Settings = (props) => {
  const [height, setHeight] = useState("auto");
  const [statement, setStatement] = useState("fixed");
  const [statementValue, setSatementValue] = useState("MIEMPRESA");

  const [display, setDisplay] = useState("none");
  const [displayOk, setDisplayOk] = useState("none");
  const [counter, setCounter] = useState(9);
  const [clicked, setClicked] = useState(false);
  const [tags, setTags] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };

  const handleDays = (value) => setDaysBetween(value);
  const handleRetry = (value) => setRetryCount(value);

  const [daysBetween, setDaysBetween] = useState(5);
  const [retryCount, setRetryCount] = useState(2);

  const changeSatementValue = (e) => {
    console.log(e);
    setSatementValue(e.target.value);
    setCounter(e.target.value.length);
  };

  const changeStatement = (e) => {
    console.log(e);
    setStatement(e.target.value);
  };
  const close = () => {
    setCounter(0);
    setHeight(0);
    setDisplay("flex");
    setClicked(true);
    setCounter(0);
  };

  const KeyCodes = {
    comma: 188,
    enter: 13,
  };

  const delimiters = [KeyCodes.comma, KeyCodes.enter];

  return (
    <>
      {/* {toHome ? <Redirect to="/home" /> : null} */}
      <Flex
        w="full"
        direction="column"
        h="100%"
        minH="90vh"
        align="center"
        justify="flex-start"
        className={"access-back"}
        bg={"white"}
        color={"gray.800"}
      >
        <Box
          width={[
            "100%", // base
            "100%", // 480px upwards
            "100%", // 768px upwards
            "70%", // 992px upwards
          ]}
          padding={5}
        >
          <Breadcrumb
            spacing="8px"
            separator={<Icon color="gray.300" name="chevron-right" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink href="/">Home</BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem>
              <BreadcrumbLink href="/about">Configuración</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <Alert mt={4} status="info">
            <AlertIcon />
            Este es un sitio de prueba, no se ejecutará ninguna acción sobre
            ventas ni configuraciones.
          </Alert>
          {/* START */}
          <Box mb={4} mt={4} maxW="32rem">
            <Heading>Configuración</Heading>
            {/* <Text fontSize="md" pt={2}>
              Editá las opciones de tu negocio
            </Text> */}
          </Box>

          <Tabs variantColor="teal">
            <TabList mb={16}>
              <Tab>
                {" "}
                <Icon
                  name="settings"
                  size="16px"
                  color="teal.500"
                  mr={4}
                />{" "}
                General
              </Tab>
              <Tab>
                {" "}
                <Icon
                  name="repeat-clock"
                  size="16px"
                  color="teal.500"
                  mr={4}
                />{" "}
                Reintentos Batch
              </Tab>
              <Tab>
                {" "}
                <Icon
                  name="drag-handle"
                  size="16px"
                  color="teal.500"
                  mr={4}
                />{" "}
                Usuarios y permisos
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <TabOne />
              </TabPanel>
              <TabPanel>
                <SectionContent
                  title={
                    <>
                      <Icon name="repeat" size="16px" color="teal.500" />
                      <Heading
                        mx={3}
                        as="h3"
                        fontSize="24px
        "
                        color="teal.500"
                      >
                        {" "}
                        Notificaciones
                      </Heading>
                    </>
                  }
                  subTitle={
                    <Text fontSize="md" pt={2}>
                      Configuración de las notificaciones
                    </Text>
                  }
                  content={
                    <>
                      <Flex w="550px" py={4} direction="column">
                        <FormLabel htmlFor="email" py={4}>
                          Seleccioná tipo de notificación via email de batch
                        </FormLabel>
                        <Select
                          onChange={(e) => changeStatement(e)}
                          value={statement}
                          placeholder="Seleccionar entilo de notificaciones"
                        >
                          <option value="fixed">
                            Enviar reporte completo una vez finalizado el batch
                          </option>
                          <option value="partner_reference">
                            Enviar reporte intermedio cada vez que se ejecute un
                            batch
                          </option>
                          <option value="description">
                            Enviar todos los día un resumen intermedio de los
                            batch
                          </option>
                        </Select>
                      </Flex>
                      <Flex w="550px" direction="column">
                        <FormLabel htmlFor="email" py={4}>
                          Destinatarios de los reportes:
                        </FormLabel>
                        <Box
                          borderWidth="2px"
                          px={10}
                          py={2}
                          borderColor="gray.200"
                        >
                          <Stack spacing={4} isInline>
                            <Tag variantColor="cyan">
                              <TagLabel>robertoflores@miempresa.com</TagLabel>
                              <TagIcon icon="close" size="12px" />
                            </Tag>

                            <Tag variantColor="cyan">
                              <TagLabel>martinlynch@miempresa.com</TagLabel>
                              <TagIcon icon="close" size="12px" />
                            </Tag>
                          </Stack>
                        </Box>
                      </Flex>
                    </>
                  }
                />

                <SectionContent
                  title={
                    <>
                      <Icon name="repeat" size="16px" color="teal.500" />
                      <Heading
                        mx={3}
                        as="h3"
                        fontSize="24px
        "
                        color="teal.500"
                      >
                        {" "}
                        Reintentos
                      </Heading>
                    </>
                  }
                  subTitle={
                    <Text fontSize="md" pt={2}>
                      Configuración general de los batch
                    </Text>
                  }
                  content={
                    <>
                      <Flex w="550px"></Flex>
                      <FormLabel htmlFor="email" py={4}>
                        Cantidad de reintentos por batch
                      </FormLabel>

                      <NumberInput
                        size="lg"
                        defaultValue={retryCount}
                        max={10}
                        onChange={handleRetry}
                        min={1}
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>

                      <FormLabel htmlFor="email" py={4}>
                        Días hábiles entre reintentos
                      </FormLabel>

                      <NumberInput
                        size="lg"
                        defaultValue={daysBetween}
                        min={1}
                        max={10}
                        onChange={handleDays}
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                      <Box pt={4}>
                        Ejemplo:
                        {[...Array(retryCount)].map((r, i) => {
                          if ((i + 1) * daysBetween > 30) {
                          } else {
                            return (
                              <Flex alignItems="center" justifyContent="center">
                                <Icon
                                  name="check-circle"
                                  size="16px"
                                  color="teal.500"
                                  mr={2}
                                />

                                <Text
                                  fontSize="md"
                                  py={2}
                                  fontWeight="bold"
                                  color="teal"
                                >
                                  {`Reintento ${i + 1}: Al día hábil número ${
                                    (i + 1) * daysBetween
                                  }`}
                                </Text>
                              </Flex>
                            );
                          }
                        })}
                      </Box>
                    </>
                  }
                />

                <SectionContent
                  title={
                    <>
                      <Icon name="repeat" size="16px" color="teal.500" />
                      <Heading
                        mx={3}
                        as="h3"
                        fontSize="24px
        "
                        color="teal.500"
                      >
                        {" "}
                        Rechazos a Reintentar
                      </Heading>
                    </>
                  }
                  subTitle={
                    <Text fontSize="md" pt={2}>
                      Ante que tipo de rechazos reintentar un cobro
                    </Text>
                  }
                  content={
                    <>
                      <Grid templateColumns="repeat(4, 1fr)" gap={6} w="550px">
                        <Box w="100%" h="10">
                          Estado
                        </Box>
                        <Box w="100%" h="10">
                          Código
                        </Box>
                        <Box w="100%" h="20">
                          Descripción
                        </Box>
                        <Box w="100%" h="10">
                          Reintentar
                        </Box>

                        <NewRow
                          code="cc_rejected_bad_filled_card_number"
                          description="Número de tarjeta incorrecto"
                          value={false}
                        />
                        <NewRow
                          code="cc_rejected_bad_filled_date"
                          description="Fecha incorrecta"
                          value={false}
                        />
                        <NewRow
                          code="cc_rejected_bad_filled_other"
                          description="Otros errores"
                          value={false}
                        />
                        <NewRow
                          code="cc_rejected_bad_filled_security_code"
                          description="Error en el CVV"
                          value={false}
                        />
                        <NewRow
                          code="cc_rejected_blacklist"
                          description="Blacklist de la tarjeta"
                          value={false}
                        />
                        <NewRow
                          code="cc_rejected_call_for_authorize"
                          description="Necesita autorización"
                          value={true}
                        />
                        <NewRow
                          code="cc_rejected_card_disabled"
                          description="Tarjeta deshabiolitada"
                          value={false}
                        />
                        <NewRow
                          code="cc_rejected_card_error"
                          description="Error en la tarjeta"
                          value={true}
                        />
                        <NewRow
                          code="cc_rejected_duplicated_payment"
                          description="Pago duplicado"
                          value={false}
                        />
                        <NewRow
                          code="cc_rejected_high_risk"
                          description="Cliente de alto riesgo"
                          value={true}
                        />
                        <NewRow
                          code="cc_rejected_insufficient_amount"
                          description="Monto insuficiente"
                          value={true}
                        />
                        <NewRow
                          code="cc_rejected_invalid_installments"
                          description="Cuotas incorrectas"
                          value={false}
                        />
                        <NewRow
                          code="cc_rejected_max_attempts"
                          description="Máximos intentos"
                          value={false}
                        />
                        <NewRow
                          code="cc_rejected_other_reason"
                          description="Rechazaado por otro motivo"
                          value={true}
                        />
                      </Grid>
                    </>
                  }
                />
              </TabPanel>
              <TabPanel>
                <Flex w="full" justifyContent="flex-end" mb={6}>
                  <Button variantColor="orange" size="lg">
                    Nuevo Usuario
                  </Button>
                </Flex>

                <Grid
                  templateColumns="repeat(4, 1fr)"
                  gap={6}
                  bg="gray.50"
                  rounded="lg"
                  p={6}
                >
                  <Flex justifyContent="flex-star" w="100%" h="10">
                    Usuario
                  </Flex>
                  <Flex justifyContent="center" w="100%" h="10">
                    Email
                  </Flex>
                  <Flex justifyContent="center" w="100%" h="10">
                    Funciones
                  </Flex>
                  <Box w="100%" h="10"></Box>
                  <NewUser
                    name={"Martin Lynch"}
                    email="martinlynch@miempresa.com"
                    role="admin"
                  />
                  <NewUser
                    name={"Fernando Mega"}
                    email="roberflores@miempresa.com"
                    role="ANALISTA"
                  />
                  <NewUser
                    name={"Roberto Flores"}
                    email="roberflores@miempresa.com"
                    role="CANAL-call"
                  />
                  <NewUser
                    name={"Juan Tierras"}
                    email="juantierras@miempresa.com"
                    role="CANAL-call"
                  />
                </Grid>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Flex>
    </>
  );
};

export default Settings;

function NewRow(props) {
  return (
    <>
      <Box w="100%" h="10">
        {status_tag("rejected")}
      </Box>
      <Box w="100%" h="10">
        {props.code}
      </Box>
      <Box w="100%" h="25">
        <Text fontSize="md" color="gray.500">
          {props.description}
        </Text>
      </Box>
      <Box w="100%" h="10">
        <Switch color="teal" size="lg" isChecked={props.value} />
      </Box>
    </>
  );
}

function NewUser(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box w="100%" h="10">
        <Flex alignItems="center" justifyContent="flex-start">
          <Avatar name={props.name} />
          <Text ml={2} fontWeight="bold" color="gray.800">
            {props.name}
          </Text>
        </Flex>
      </Box>
      <Box w="100%" h="10">
        {props.email}
      </Box>
      <Flex justifyContent="center" w="100%" h="10">
        <Tag variantColor="cyan">
          <TagLabel>{props.role.toUpperCase()}</TagLabel>
          <TagIcon icon="check" size="12px" />
        </Tag>
      </Flex>
      <Flex justifyContent="flex-end" w="100%" h="10">
        <IconButton
          onClick={onOpen}
          variantColor="teal"
          aria-label="borrar usuario"
          icon="delete"
        />
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Crear nuevo Batch</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Está seguro que desea eliminar al usuario {props.name}?
          </ModalBody>

          <ModalFooter>
            <Button variantColor="teal" mr={3} onClick={onClose}>
              Cerrar
            </Button>
            <Button variant="ghost">Borrar usuarios</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

const status_tag = (status) => {
  let status_msg = {
    approved: "APROBADO",
    pending: "PENDIENTE",
    rejected: "RECHAZADO",
    finalizado: "FINALIZADO",
  };
  let status_color = {
    approved: "green",
    pending: "orange",
    rejected: "red",
    finalizado: "blue",
  };
  return (
    <Tag
      size="sm"
      rounded="full"
      variant="solid"
      variantColor={status_color[status]}
    >
      <TagLabel>{status_msg[status]}</TagLabel>
    </Tag>
  );
};

function PasswordInput(props) {
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  return (
    <Stack mb={4}>
      <FormLabel htmlFor="email">{props.title}</FormLabel>
      <InputGroup size="md">
        <Input
          pr="5.2rem"
          type={show ? "text" : "password"}
          placeholder="access_token de producción"
          value={props.text}
          fontSize="15px"
          w="550px"
        />
        <InputRightElement width="4.6rem">
          <Button h="2rem" size="sm" onClick={handleClick}>
            {show ? "Ocultar" : "Mostrar"}
          </Button>
        </InputRightElement>
      </InputGroup>
      <FormHelperText id="email-helper-text">{props.hint}</FormHelperText>
    </Stack>
  );
}

function SectionContent(props) {
  return (
    <>
      <Flex
        alignItems="flex-start"
        justifyContent="center"
        direction="column"
        my={4}
        mb={4}
      >
        <Flex alignItems="flex-start" justifyContent="center">
          <Stack w="600px">
            <Flex alignItems="center" justifyContent="flex-start">
              {props.title}
            </Flex>

            {props.subTitle}
          </Stack>
          <Flex
            w="70%"
            pt={6}
            alignItems="center"
            justifyContent="center"
            direction="column"
          >
            {props.content}
          </Flex>
        </Flex>
      </Flex>
      <Divider />
    </>
  );
}

function TabOne() {
  const [height, setHeight] = useState("auto");
  const [statement, setStatement] = useState("fixed");
  const [statementValue, setSatementValue] = useState("MIEMPRESA");

  const [display, setDisplay] = useState("none");
  const [displayOk, setDisplayOk] = useState("none");
  const [counter, setCounter] = useState(9);
  const [clicked, setClicked] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const changeSatementValue = (e) => {
    console.log(e);
    setSatementValue(e.target.value);
    setCounter(e.target.value.length);
  };

  const changeStatement = (e) => {
    console.log(e);
    setStatement(e.target.value);
  };
  return (
    <>
      <SectionContent
        title={
          <>
            <Icon name="info" size="16px" color="teal.500" />
            <Heading mx={3} as="h3" fontSize="24px" color="teal.500">
              {" "}
              Datos de la empresa
            </Heading>
          </>
        }
        subTitle={
          <Text fontSize="md" pt={2}>
            Completá los datos básicos de tu empresa
          </Text>
        }
        content={
          <>
            <Stack spacing={4}>
              <FormLabel htmlFor="email">Web de la empresa</FormLabel>
              <InputGroup size="md">
                <InputLeftAddon children="https://" />
                <Input
                  rounded="0"
                  placeholder="mysite.com"
                  fontSize="15px"
                  value="www.miempresa.com.ar"
                  w="450px"
                />
              </InputGroup>
              <FormLabel htmlFor="email">
                Descripción en la tarjeta del cliente
              </FormLabel>
              <Select
                onChange={(e) => changeStatement(e)}
                value={statement}
                placeholder="Elegí una opción para que figure en la tarjeta"
              >
                <option value="fixed">fixed</option>
                <option value="partner_reference">partner_reference</option>
                <option value="description">description</option>
              </Select>

              {statement == "fixed" && (
                <InputGroup size="md">
                  <InputLeftAddon children="MERCADOPAGO*" />
                  <Input
                    rounded="0"
                    placeholder="mysite.com"
                    fontSize="15px"
                    onChange={(e) => changeSatementValue(e)}
                    value={statementValue}
                    w="380px"
                  />
                </InputGroup>
              )}
              {statement == "fixed" && (
                <div>{`máximos caracteres: ${counter}/13`}</div>
              )}

              {statement == "partner_reference" && (
                <div>{`Se agregará al statement el valor que tenga el campo partner_reference`}</div>
              )}

              {statement == "description" && (
                <div>{`Se agregará al statement el valor que tenga el campo description`}</div>
              )}
            </Stack>
          </>
        }
      />
      {/*  */}
      <SectionContent
        title={
          <>
            <Icon name="lock" size="16px" color="teal.500" />
            <Heading
              mx={3}
              as="h3"
              fontSize="24px
        "
              color="teal.500"
            >
              {" "}
              Credenciales de MercadoPago
            </Heading>
          </>
        }
        subTitle={
          <Text fontSize="md" pt={2}>
            Configurá las credenciales de producción de la cuenta de MercadoPago
            de tu empresa
          </Text>
        }
        content={
          <>
            <PasswordInput
              text="TEST-bc30491d-88d2-9876-aabb-6d81512dc768"
              hint="Podés obtener tu public_key desde la parte de Credenciales de MP"
              title="Public Key"
            />
            <PasswordInput
              text="TEST-979024972994344-062512-d54b7a7f99862ee15f510c6eccc67ec8-579999742"
              hint="Podés obtener tu access_token desde la parte de Credenciales de MP"
              title="Access Token"
            />
          </>
        }
      />
      {/*  */}
      <SectionContent
        title={
          <>
            <Icon name="warning-2" size="16px" color="teal.500" />
            <Heading
              mx={3}
              as="h3"
              fontSize="24px
        "
              color="teal.500"
            >
              {" "}
              Modo matenimeinto
            </Heading>
          </>
        }
        subTitle={
          <Text fontSize="md" pt={2}>
            Con esta opción puede pausar las operaciones de la aplicación.
          </Text>
        }
        content={
          <>
            <Stack align="left" w="550px">
              <Text fontSize="md" pt={2}>
                Pausar aplicación
              </Text>
              <Switch size="lg" />
            </Stack>
          </>
        }
      />
    </>
  );
}
