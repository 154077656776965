
import { theme } from "@chakra-ui/core";

export default {
    colors: {
        ...theme.colors,
        brand: {
          900: "#1a365d",
          800: "#153e75",
          700: "#2a69ac",
        },
      },
  };