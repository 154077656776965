import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Flex,
  Text,
  Button,
  PseudoBox,
  BreadcrumbItem,
  BreadcrumbLink,
  Breadcrumb,
  Icon,
  Alert,
  AlertIcon,
  Grid,
  Stack,
  Image,
  Tag,
  TagLabel,
  TagCloseButton,
  Divider,
  Progress,
  Avatar,
  Badge,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure
} from "@chakra-ui/core";
import AnimateHeight from "react-animate-height";
import numeral from "numeral";
import { octubre } from "../data/octubre_file";
import Countdown from "react-countdown";
import moment from "moment";
import { useTable, useColumnOrder } from "react-table";
import {
  Redirect
} from "react-router-dom";


const payment_type_msg = {
  batch_payment: "Batch Payment",
  regular_payment: "Regular Payment",
};
const credit_card_img = {
  master: require("../images/cards/master.png"),
  visa: require("../images/cards/visa.png"),
  amex: require("../images/cards/amex.png"),
};

const NewBatch = (props) => {
  const [height, setHeight] = useState("auto");
  const [display, setDisplay] = useState("none");
  const [displayOk, setDisplayOk] = useState("none");
  const [counter, setCounter] = useState(1);
  const [running, setRunning] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [toNew, setToNew] = useState(false);

  const data = React.useMemo(() => [...octubre], []);

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id", // accessor is the "key" in the data
      },
      {
        Header: "Nombre",
        accessor: "first_name",
      },
      {
        Header: "Apellido",
        accessor: "last_name",
      },
      {
        Header: "ID Empresa",
        accessor: "external_id",
      },
      {
        Header: "Email",
        accessor: "email",
      },
   
      {
        Header: "Reference Empresa",
        accessor: "partner_reference",
      },
      {
        Header: "Descripción",
        accessor: "description",
      },
      {
        Header: "Monto",
        accessor: "transaction_amount",
      },

    ],
    []
  );

  const columnsRunning = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id", // accessor is the "key" in the data
        show: true,
      },
      {
        Header: "Nombre",
        accessor: "first_name",
      },
      {
        Header: "Apellido",
        accessor: "last_name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Monto",
        accessor: "transaction_amount",
      },
      {
        Header: "Reference Empresa",
        accessor: "partner_reference",
      },
    
    ],
    []
  );

    

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setColumnOrder,
  } = useTable( {columns, data, initialState: { pageIndex: 0, hiddenColumns: running ? [ 'email', 'description'] : [] } } );

  const toogle = () => {
    onClose()
    setToNew(true)
  }

  return (
    <>
      {toNew ? <Redirect to="/batch/56" /> : null}
      <Flex
        w="full"
        direction="column"
        minH="100vh"
        align="center"
        justify="flex-start"
        className={"access-back"}
        bg={"white"}
        color={"gray.800"}
      >
        <Box
          width={[
            "100%", // base
            "100%", // 480px upwards
            "100%", // 768px upwards
            "70%", // 992px upwards
          ]}
          padding={5}
        >
          <Breadcrumb
            spacing="8px"
            separator={<Icon color="gray.300" name="chevron-right" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink href="/">Home</BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem>
              <BreadcrumbLink href="/about">Batch</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink href="/batch/new">Nuevo Batch</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <Alert mt={4} status="info">
            <AlertIcon />
            Este es un sitio de prueba, no se ejecutará ninguna acción sobre
            ventas ni configuraciones.
          </Alert>
          {/* START */}
          <Box mb={4} mt={4} maxW="32rem">
            <Heading>Nuevo Batch</Heading>
            <Text ml={1} fontSize="sm">
              Crea nuevos pedidos de pago batch
            </Text>
          </Box>
          <Divider />

          <Flex
            w="full"
            alignItems="center"
            justifyContent="flex-start"
            my={4}
            px={16}
          >
            <Flex w="full" alignItems="center" justifyContent="flex-start">
              <Image h="40px" src={require("../images/csv.png")} />
              <Text mx={4} color="gray.500" fontSize="lg">
                pagos_octubre.csv
              </Text>
              <Icon mr={4} name="check" size="32px" color="green.500" />
              Archivo procesado
              <Text mx={4} color="gray.500" fontSize="lg">
                65 transacciones
              </Text>
            </Flex>
            <Button variantColor="orange" size="lg" onClick={onOpen}>
              CREAR BATCH
            </Button>
          </Flex>

          <Divider />

          <Flex
            alignItems="center"
            justifyContent="center"
            direction="column"
            my={4}
            mb={4}
          >
            <Flex alignItems="center" justifyContent="center">
              <Icon name="check" size="16px" />
              <Heading mx={3} as="h3" size="lg">
                {" "}
                DETALLE DE BATCH
              </Heading>
            </Flex>

            <Text fontSize="sm">
              Lista de los Batch que ya están finalizados y su estado es final
            </Text>
            <Flex w="full" pt={6} alignItems="center" justifyContent="center">
              <Box
                as="table"
                border={1}
                borderStyle="solid"
                borderTopLeftRadius={3}
                borderSpacing={0}
                {...getTableProps()}
              >
                <Box as="thead" rounded="lg">
                  {headerGroups.map((headerGroup) => (
                    <Box
                      as="tr"
                      rounded="lg"
                      {...headerGroup.getHeaderGroupProps()}
                    >
                      {headerGroup.headers.map((column) => (
                        <Box
                          as="th"
                          m={0}
                          p="0.5rem"
                          borderBottom={1}
                          borderBottomStyle="solid"
                          borderRight={1}
                          bg={"teal.400"}
                          borderRightStyle="solid"
                          {...column.getHeaderProps()}
                        >
                          {column.render("Header")}
                        </Box>
                      ))}
                    </Box>
                  ))}
                </Box>
                <Box as="tbody" {...getTableBodyProps()}>
                  {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <Box as="tr" {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          console.log()
                          return (
                            <Box
                              as="td"
                              m={0}
                              p="0.5rem"
                              borderBottom={1}
                              borderBottomStyle="solid"
                              borderRight={1}
                              borderRightStyle="solid"
                              {...cell.getCellProps()}
                            >
                              
                              {!(cell.column["Header"] == 'Monto') &&cell.render("Cell")}
                              {cell.column["Header"] == 'Monto' && numeral(cell.render("Cell").props.cell.value).format("$0,0.00") }
                            </Box>
                          );
                        })}
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Flex>
          </Flex>
        </Box>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Crear nuevo Batch</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Está seguro que desea crear un nuevo batch con un total de 65 nuevas transacciones?
          </ModalBody>

          <ModalFooter>
            <Button variantColor="teal" mr={3} onClick={onClose}>
              Cerrar
            </Button>
            <Button variant="ghost" onClick={() => toogle()}>CREAR BATCH</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default NewBatch;

const status_tag = (status) => {
  let status_msg = {
    approved: "APROBADO",
    pending: "PENDIENTE",
    rejected: "RECHAZADO",
    finalizado: "FINALIZADO",
  };
  let status_color = {
    approved: "green",
    pending: "orange",
    rejected: "red",
    finalizado: "blue",
  };
  return (
    <Tag
      size="sm"
      rounded="full"
      variant="solid"
      variantColor={status_color[status]}
    >
      <TagLabel>{status_msg[status]}</TagLabel>
    </Tag>
  );
};
