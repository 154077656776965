import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Flex,
  Text,
  Button,
  PseudoBox,
  BreadcrumbItem,
  BreadcrumbLink,
  Breadcrumb,
  Icon,
  Alert,
  AlertIcon,
  Grid,
  Stack,
  Image,
  Tag,
  TagLabel,
  TagCloseButton,
  Divider,
  Progress,
  Avatar,
  Badge,
} from "@chakra-ui/core";
import AnimateHeight from "react-animate-height";
import numeral from "numeral";
import { batchs } from "../data/batchs_list";
import Countdown from "react-countdown";
import moment from "moment";
import { Redirect } from "react-router-dom";

const payment_type_msg = {
  batch_payment: "Batch Payment",
  regular_payment: "Regular Payment",
};
const credit_card_img = {
  master: require("../images/cards/master.png"),
  visa: require("../images/cards/visa.png"),
  amex: require("../images/cards/amex.png"),
};

const Batch = (props) => {
  const [height, setHeight] = useState("auto");
  const [display, setDisplay] = useState("none");
  const [displayOk, setDisplayOk] = useState("none");
  const [counter, setCounter] = useState(1);
  const [clicked, setClicked] = useState(false);
  const [toNewBatch, setToNewBatch] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const min = 0;
      const max = 25;
      const random = min + Math.random() * (max - min);
      setCounter(counter + random);
      // console.log(counter)
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [counter]);

  useEffect(() => {
    if (counter >= 100 && clicked) {
      setDisplay("none");
      setDisplayOk("flex");
      setTimeout(() => setToNewBatch(true), 1500);
    }
  }, [counter]);

  const close = () => {
    setCounter(0);
    setHeight(0);
    setDisplay("flex");
    setClicked(true);
    setCounter(0);
  };
  return (
    <>
      {toNewBatch ? <Redirect to="/batch/new" /> : null}
      <Flex
        w="full"
        direction="column"
        minH="100vh"
        align="center"
        justify="flex-start"
        className={"access-back"}
        bg={"white"}
        color={"gray.800"}
      >
        <Box
          width={[
            "100%", // base
            "100%", // 480px upwards
            "100%", // 768px upwards
            "70%", // 992px upwards
          ]}
          padding={5}
        >
          <Breadcrumb
            spacing="8px"
            separator={<Icon color="gray.300" name="chevron-right" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink href="/">Home</BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem>
              <BreadcrumbLink href="/batch">Batch</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <Alert mt={4} status="info">
            <AlertIcon />
            Este es un sitio de prueba, no se ejecutará ninguna acción sobre
            ventas ni configuraciones.
          </Alert>
          {/* START */}
          <Box mb={4} mt={4} maxW="32rem">
            <Heading>Batch</Heading>
            <Text ml={1} fontSize="sm">
              Explorá y crea nuevos pedidos de pago batch
            </Text>
          </Box>
          <Divider />
          <AnimateHeight id="example-panel" duration={500} height={height}>
            <Flex
              alignItems="center"
              justifyContent="center"
              direction="column"
              my={4}
              mb={4}
            >
              <Flex alignItems="center" justifyContent="center">
                <Icon name="check" size="16px" />
                <Heading mx={3} as="h3" size="lg">
                  {" "}
                  CREAR NUEVO BATCH
                </Heading>
              </Flex>

              <Text fontSize="sm">
                Podés crear hasta 10k de cobros en simultaneo
              </Text>

              <PseudoBox
                d="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                borderStyle="dashed"
                _hover={{ bg: "gray.50" }}
                py={12}
                my={4}
                onClick={() => close()}
                borderWidth="3px"
                color="gray"
                rounded="lg"
                borderColor="gray.200"
                w="full"
              >
                <Icon name="add" size="32px" color="green.300" />
                <Text color="gray.500" fontSize="lg">
                  Agregar un archivo CSV
                </Text>
              </PseudoBox>
              <Flex>
                <Flex
                  rounded="lg"
                  align="center"
                  px={12}
                  py={6}
                  justify="center"
                  direction="column"
                  bg="orange.50"
                >
                  Demo:
                  <Button
                    leftIcon="add"
                    my={4}
                    size="xs"
                    variantColor="orange"
                    onClick={() => close()}
                    variant="outline"
                  >
                    Procesar pagos_octubre.csv
                  </Button>
                  <Button
                    leftIcon="download"
                    size="xs"
                    variantColor="orange"
                    variant="outline"
                  >
                    Descargar pagos_octubre.csv
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </AnimateHeight>
          <Flex
            w="full"
            alignItems="center"
            justifyContent="flex-start"
            style={{ display: displayOk }}
            my={4}
          >
            <Image h="40px" src={require("../images/csv.png")} />
            <Text mx={4} color="gray.500" fontSize="lg">
              pagos_octubre.csv
            </Text>
            <Icon mr={4} name="check" size="32px" color="green.500" />
            Archivo procesado
          </Flex>
          <Flex
            w="full"
            alignItems="center"
            justifyContent="flex-start"
            style={{ display: display }}
            my={4}
          >
            <Image h="40px" src={require("../images/csv.png")} />
            <Text mx={4} color="gray.500" fontSize="lg">
              pagos_octubre.csv
            </Text>
            <Box w="full" h="12px" mx={4}>
              <Progress size="md" value={counter} hasStripe isAnimated />
            </Box>
          </Flex>
          <Divider />

          <Flex
            alignItems="center"
            justifyContent="center"
            direction="column"
            my={4}
            mb={4}
          >
            <Flex alignItems="center" justifyContent="center">
              <Icon name="check" size="16px" />
              <Heading mx={3} as="h3" size="lg">
                {" "}
                BATCHs ACTIVOS
              </Heading>
            </Flex>

            <Text fontSize="sm">
              Lista de los Batch que ya están finalizados y su estado es final
            </Text>
            <Flex
              w="full"
              pt={6}
              alignItems="center"
              justifyContent="flex-start"
              overflow="scroll"
              
            >
              {/*  */}
              <PseudoBox
                mr="30px"
                as="a"
                href={`/fund/`}
                minW="300px"
                borderColor={"gray.100"}
                rounded="lg"
                borderWidth="2px"
                overflow="hidden"
                bg={"white"}
                color={"gray.500"}
                _hover={{ bg: "gray.50" }}
              >
                <Box
                  d="flex"
                  py={6}
                  alignItems="center"
                  justifyContent="center"
                  fontWeight="semibold"
                  as="h4"
                  lineHeight="tight"
                  bg={"teal.300"}
                  borderBottom={"1px solid #EDF2F7"}
                >
                  <Heading mx={3} as="h4" size="xl" color="white">
                    BATCH#54
                  </Heading>
                </Box>

                <Flex p="4" direction="column">
                  <Box
                    color="gray.500"
                    fontWeight="semibold"
                    textTransform="uppercase"
                  >
                    Próxima ejecución en:
                  </Box>
                  <Box
                    d="flex"
                    h={"60px"}
                    flexWrap={"wrap"}
                    alignItems="baseline"
                  >
                    <Countdown date={new Date("2020-11-04")} />
                  </Box>
                  <Box
                    color="gray.500"
                    fontWeight="semibold"
                    textTransform="uppercase"
                  >
                    Aprobados
                  </Box>

                  <Box h={"60px"}>
                    <Box as="span" color="gray.600">
                      65%
                    </Box>
                  </Box>
                  <Box>
                    <Text
                      color="gray.500"
                      fontWeight="semibold"
                      textTransform="uppercase"
                    >
                      Operaciones agregadas
                    </Text>
                    <Box as="span" color="gray.600">
                      36
                    </Box>
                  </Box>
                </Flex>
              </PseudoBox>
              {/*  */}

              {/*  */}
              <PseudoBox
                mr="30px"
                as="a"
                href={`/fund/`}
                minW="300px"
                borderColor={"gray.100"}
                rounded="lg"
                borderWidth="2px"
                overflow="hidden"
                bg={"white"}
                color={"gray.500"}
                _hover={{ bg: "gray.50" }}
              >
                <Box
                  d="flex"
                  py={6}
                  alignItems="center"
                  justifyContent="center"
                  fontWeight="semibold"
                  as="h4"
                  lineHeight="tight"
                  bg={"teal.300"}
                  borderBottom={"1px solid #EDF2F7"}
                >
                  <Heading mx={3} as="h4" size="xl" color="white">
                    BATCH#55
                  </Heading>
                </Box>

                <Flex p="4" direction="column">
                  <Box
                    color="gray.500"
                    fontWeight="semibold"
                    textTransform="uppercase"
                  >
                    Próxima ejecución en:
                  </Box>
                  <Box
                    d="flex"
                    h={"60px"}
                    flexWrap={"wrap"}
                    alignItems="baseline"
                  >
                    <Countdown date={new Date("2020-11-05")} />
                  </Box>
                  <Box
                    color="gray.500"
                    fontWeight="semibold"
                    textTransform="uppercase"
                  >
                    Aprobados
                  </Box>

                  <Box h={"60px"}>
                    <Box as="span" color="gray.600">
                      45%
                    </Box>
                  </Box>
                  <Box>
                    <Text
                      color="gray.500"
                      fontWeight="semibold"
                      textTransform="uppercase"
                    >
                      Operaciones agregadas
                    </Text>
                    <Box as="span" color="gray.600">
                      57
                    </Box>
                  </Box>
                </Flex>
              </PseudoBox>
              {/*  */}
            </Flex>
          </Flex>

          <Divider my={12} />
          <Flex
            alignItems="center"
            justifyContent="center"
            direction="column"
            my={4}
            mb={4}
          >
            <Flex alignItems="center" justifyContent="center">
              <Icon name="check" size="16px" />
              <Heading mx={3} as="h3" size="lg">
                {" "}
                BATCHs FINALIZADOS
              </Heading>
            </Flex>

            <Text fontSize="sm">
              Lista de los Batch que están activos y van a reintentarse
            </Text>
          </Flex>

          <Box
            maxW="full"
            borderColor={"gray.100"}
            rounded="lg"
            borderWidth="2px"
            overflow="hidden"
            bg={"white"}
            color={"gray.800"}
          >
            {/* line */}
            {batchs.map((p) => {
              return (
                <PseudoBox
                  as="a"
                  href="#"
                  _hover={{ bg: "gray.50" }}
                  d="flex"
                  py={3}
                  alignItems="center"
                  justifyContent="center"
                  lineHeight="tight"
                  borderBottom={"1px solid #EDF2F7"}
                >
                  <Grid
                    w="full"
                    templateColumns={{
                      md: "auto auto auto auto 200px",
                      sm: "auto auto auto auto auto",
                    }}
                    gap={3}
                  >
                    <Box pl={4}>
                      <Flex
                        w="full"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={2}
                      >
                        <Flex
                          rounded="full"
                          h="64px"
                          w="64px"
                          bg="teal.600"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Text color={"white"} fontSize="18px">
                            #
                          </Text>
                          <Text
                            // mr={4}
                            fontWeight="semibold"
                            color={"white"}
                            fontSize="24px"
                          >
                            {p.id}
                          </Text>
                        </Flex>
                      </Flex>
                    </Box>
                    <Flex
                      w="full"
                      justifyContent="center"
                      alignItems="center"
                      direction="column"
                    >
                      <Flex
                        w="full"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Text
                          alignItems="center"
                          mr={4}
                          fontWeight="semibold"
                          color={"gray.500"}
                          fontSize="sm"
                        >
                          Ejecutado
                        </Text>
                      </Flex>

                      <Text
                        alignItems="center"
                        color={"gray.800"}
                        fontSize="sm"
                      >
                        {moment(p.from).format("DD MMM")} -{" "}
                        {moment(p.to).format("DD MMM")}
                      </Text>
                    </Flex>

                    <Flex w="full" justifyContent="center" alignItems="center">
                      {status_tag("finalizado")}
                    </Flex>

                    <Flex
                      w="full"
                      justifyContent="center"
                      alignItems="center"
                      direction="column"
                    >
                      <Text
                        alignItems="center"
                        fontWeight="semibold"
                        color={"gray.500"}
                        fontSize="sm"
                      >
                        Aprobado
                      </Text>
                      <Text
                        alignItems="center"
                        color={"gray.800"}
                        fontSize="sm"
                      >
                        {p.approved} / {p.total}
                      </Text>
                    </Flex>

                    <Flex
                      h="full"
                      pr={4}
                      justifyContent="center"
                      alignItems="flex-end"
                      direction="column"
                    >
                      <Heading as="h5" size="md" textAlign="right">
                        {numeral(p.amount).format("$0,0.00")}
                      </Heading>
                    </Flex>
                  </Grid>
                </PseudoBox>
              );
            })}
          </Box>
        </Box>
      </Flex>
    </>
  );
};

export default Batch;

const status_tag = (status) => {
  let status_msg = {
    approved: "APROBADO",
    pending: "PENDIENTE",
    rejected: "RECHAZADO",
    finalizado: "FINALIZADO",
  };
  let status_color = {
    approved: "green",
    pending: "orange",
    rejected: "red",
    finalizado: "blue",
  };
  return (
    <Tag
      size="sm"
      rounded="full"
      variant="solid"
      variantColor={status_color[status]}
    >
      <TagLabel>{status_msg[status]}</TagLabel>
    </Tag>
  );
};
