export const batchs = [
    {
      id: 53,
      from:"2020-07-02",
      to: "2020-07-17",
      approved: 28,
      total: 35,
      provider_reference: 10454471889,
      amount: 233574.66
    },
    {
      id: 52,
      from:"2020-06-02",
      to: "2020-06-17",
      approved: 48,
      total: 59,
      provider_reference: 10454471889,
      amount: 533588.30
    },
    {
      id: 51,
      from:"2020-05-02",
      to: "2020-05-17",
      approved: 48,
      total: 65,
      provider_reference: 10454471889,
      amount: 658902.66
    },
    {
      id: 50,
      from:"2020-04-02",
      to: "2020-04-17",
      approved: 28,
      total: 39,
      provider_reference: 10454471889,
      amount: 533574.2
    },
    {
      id: 49,
      from:"2020-03-02",
      to: "2020-03-17",
      approved: 28,
      total: 65,
      provider_reference: 10454471889,
      amount: 333574.38
    },
    {
      id: 48,
      from:"2020-02-02",
      to: "2020-02-17",
      approved: 28,
      total: 55,
      provider_reference: 10454471889,
      amount: 240678.66
    },
    

    
];
