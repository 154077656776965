import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { ThemeProvider, CSSReset } from "@chakra-ui/core";
import customTheme from "./styles/theme";
import Home from "./pages/home";
import './App.scss';

import Header from "./pages/header";
import Activities from "./pages/activities";
import Clients from "./pages/clients";
import Batch from "./pages/batch";
import NewBatch from "./pages/newBatch";
import ShowBatch from "./pages/showBatch";
import Settings from "./pages/settings";
import Footer from "./pages/footer";
import Landing from "./pages/landing";

export default function App() {
  return (
    <ThemeProvider>
      <CSSReset />
      <Router>
        <Header />
        <Switch>
          <Route path="/activities">
            <Activities />
          </Route>
          <Route path="/clients">
            <Clients />
          </Route>
          <Route path="/batch/new">
            <NewBatch />
          </Route>
          <Route path="/batch/:id">
            <ShowBatch />
          </Route>
          <Route path="/batch">
            <Batch />
          </Route>
          <Route path="/settings">
            <Settings />
          </Route>
          <Route path="/">
            <Landing />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </ThemeProvider>
  );
}
