export const payments = [
    {
      reference: "434606d3-f75e-43ed-b2b9-57cc78867f23",
      amount: 6808.02,
      name: "Alvis Burgan",
      email: "aburgan0@typepad.com",
      dni: 35862785,
      provider_reference: 10454471889,
      date: "2020-07-21",
      status: "approved",
      payment_source: "regular_payment",
      payment_name: "visa",
      payment_type: "credit_card",
      batch_id: 54,
    },
    {
      reference: "b7e95b49-11fa-448f-8d7f-b5c1037e4d02",
      amount: 3574.66,
      name: "Federica Shearsby",
      email: "fshearsby1@google.co.uk",
      dni: 36693581,
      provider_reference: 10538608336,
      date: "2020-08-14",
      status: "approved",
      payment_source: "regular_payment",
      payment_name: "visa",
      payment_type: "credit_card",
      batch_id: 56,
    },
    {
      reference: "3365c134-279c-40ed-9fea-33aaf5ea673d",
      amount: 7759.16,
      name: "Stevana Lampard",
      email: "slampard2@howstuffworks.com",
      dni: 35543964,
      provider_reference: 10510533246,
      date: "2019-11-22",
      status: "approved",
      payment_source: "batch_payment",
      payment_name: "visa",
      payment_type: "credit_card",
      batch_id: 56,
    },
    {
      reference: "a3d8fa52-0ad1-4ef9-8506-c24b4ea43465",
      amount: 1782.78,
      name: "Sutherland Laytham",
      email: "slaytham3@sphinn.com",
      dni: 35785072,
      provider_reference: 10488421289,
      date: "2019-11-16",
      status: "approved",
      payment_source: "batch_payment",
      payment_name: "visa",
      payment_type: "credit_card",
      batch_id: 56,
    },
    {
      reference: "7445366e-513c-4066-b0f3-b55df14cb3a3",
      amount: 6617.1,
      name: "Erastus Lowen",
      email: "elowen4@gizmodo.com",
      dni: 38004464,
      provider_reference: 10504408153,
      date: "2020-02-09",
      status: "approved",
      payment_source: "batch_payment",
      payment_name: "master",
      payment_type: "credit_card",
      batch_id: 55,
    },
    {
      reference: "52c4fd44-4a93-4259-9de4-5f0000d1ccee",
      amount: 9186.95,
      name: "Stavro Bruntjen",
      email: "sbruntjen5@newsvine.com",
      dni: 35440040,
      provider_reference: 10531735535,
      date: "2019-11-23",
      status: "approved",
      payment_source: "regular_payment",
      payment_name: "master",
      payment_type: "credit_card",
      batch_id: 55,
    },
    {
      reference: "2ebe6dbd-87d7-455a-b081-c35ea4cba734",
      amount: 4125.1,
      name: "Nicolai Oxbury",
      email: "noxbury6@chron.com",
      dni: 31262028,
      provider_reference: 10469904373,
      date: "2020-02-25",
      status: "approved",
      payment_source: "regular_payment",
      payment_name: "visa",
      payment_type: "credit_card",
      batch_id: 55,
    },
    {
      reference: "2306c6fc-41f0-4a1c-9300-79347599a15f",
      amount: 5323.49,
      name: "Burch Menpes",
      email: "bmenpes7@discuz.net",
      dni: 37384009,
      provider_reference: 10465584415,
      date: "2020-07-25",
      status: "rejected",
      payment_source: "batch_payment",
      payment_name: "visa",
      payment_type: "credit_card",
      batch_id: 54,
    },
    {
      reference: "afbb91a8-5b3d-403f-b952-092a87b8d123",
      amount: 3686.83,
      name: "Yetta Raccio",
      email: "yraccio8@amazon.co.jp",
      dni: 31610633,
      provider_reference: 10522994693,
      date: "2020-08-06",
      status: "rejected",
      payment_source: "regular_payment",
      payment_name: "visa",
      payment_type: "credit_card",
      batch_id: 56,
    },
    {
      reference: "98b7e1c2-e067-4613-a144-daec83d2d5c5",
      amount: 4675.35,
      name: "Mercedes Henzer",
      email: "mhenzer9@washington.edu",
      dni: 33009971,
      provider_reference: 10479472682,
      date: "2020-09-25",
      status: "approved",
      payment_source: "batch_payment",
      payment_name: "master",
      payment_type: "credit_card",
      batch_id: 56,
    },
    {
      reference: "79fa7563-7425-460e-b50a-70a4e870125d",
      amount: 8926.5,
      name: "Susann Reiner",
      email: "sreinera@wp.com",
      dni: 35795622,
      provider_reference: 10538022370,
      date: "2020-01-29",
      status: "approved",
      payment_source: "regular_payment",
      payment_name: "amex",
      payment_type: "credit_card",
      batch_id: 56,
    },
    {
      reference: "62c114b2-e791-4f77-b025-72ca50a1ac58",
      amount: 8757.32,
      name: "Jordan Hullbrook",
      email: "jhullbrookb@canalblog.com",
      dni: 39965300,
      provider_reference: 10530002386,
      date: "2020-02-10",
      status: "approved",
      payment_source: "regular_payment",
      payment_name: "visa",
      payment_type: "credit_card",
      batch_id: 54,
    },
    {
      reference: "1cc0e6f7-1b70-4e7b-9a88-2cfd58776ab7",
      amount: 9179.84,
      name: "Jerrylee McCromley",
      email: "jmccromleyc@admin.ch",
      dni: 31835620,
      provider_reference: 10508676940,
      date: "2020-06-22",
      status: "approved",
      payment_source: "regular_payment",
      payment_name: "amex",
      payment_type: "credit_card",
      batch_id: 54,
    },
    {
      reference: "a9818b51-9628-4c28-af5a-8e77d099d13d",
      amount: 6391.06,
      name: "Georgeanne Endle",
      email: "gendled@bizjournals.com",
      dni: 32959370,
      provider_reference: 10522768041,
      date: "2020-09-12",
      status: "approved",
      payment_source: "batch_payment",
      payment_name: "visa",
      payment_type: "credit_card",
      batch_id: 54,
    },
    {
      reference: "affc6df5-7568-46e5-9285-02b8b6fbf818",
      amount: 1418.36,
      name: "Hube Tarr",
      email: "htarre@creativecommons.org",
      dni: 37689278,
      provider_reference: 10467801739,
      date: "2020-09-23",
      status: "approved",
      payment_source: "batch_payment",
      payment_name: "visa",
      payment_type: "credit_card",
      batch_id: 56,
    },
    {
      reference: "3c9ebe2c-0b2b-4d03-a3bf-8eb21db74a3f",
      amount: 7133.22,
      name: "Tina Roseman",
      email: "trosemanf@va.gov",
      dni: 38816918,
      provider_reference: 10457947317,
      date: "2020-05-18",
      status: "approved",
      payment_source: "batch_payment",
      payment_name: "visa",
      payment_type: "credit_card",
      batch_id: 56,
    },
    {
      reference: "c61dac5c-2cef-42ec-b4aa-8a5c891dd02b",
      amount: 7564.8,
      name: "Halli Iacabucci",
      email: "hiacabuccig@fotki.com",
      dni: 36832468,
      provider_reference: 10527245337,
      date: "2019-12-22",
      status: "approved",
      payment_source: "regular_payment",
      payment_name: "amex",
      payment_type: "credit_card",
      batch_id: 54,
    },
    {
      reference: "74ad8fb1-fd9b-41b6-8013-1f14c21d3e5c",
      amount: 2753.82,
      name: "Monica Bunson",
      email: "mbunsonh@histats.com",
      dni: 34903680,
      provider_reference: 10462566881,
      date: "2020-01-31",
      status: "approved",
      payment_source: "regular_payment",
      payment_name: "visa",
      payment_type: "credit_card",
      batch_id: 55,
    },
    {
      reference: "876cd9c8-7881-4bcc-8837-9cab550c638a",
      amount: 9267.34,
      name: "Roz McGarrahan",
      email: "rmcgarrahani@comcast.net",
      dni: 35678488,
      provider_reference: 10499164138,
      date: "2020-02-07",
      status: "approved",
      payment_source: "batch_payment",
      payment_name: "amex",
      payment_type: "credit_card",
      batch_id: 56,
    },
    {
      reference: "302793ba-3e52-41a1-9417-1a2f97af4e74",
      amount: 8595.1,
      name: "Mitchell Sommerville",
      email: "msommervillej@geocities.com",
      dni: 35456901,
      provider_reference: 10531204563,
      date: "2019-11-17",
      status: "approved",
      payment_source: "batch_payment",
      payment_name: "visa",
      payment_type: "credit_card",
      batch_id: 56,
    },
    {
      reference: "45852527-89c3-43dc-bdc1-df7ceb59d075",
      amount: 2702.0,
      name: "Faulkner Beedie",
      email: "fbeediek@ning.com",
      dni: 39954660,
      provider_reference: 10501198036,
      date: "2020-07-11",
      status: "approved",
      payment_source: "regular_payment",
      payment_name: "master",
      payment_type: "credit_card",
      batch_id: 54,
    },
    {
      reference: "b9bb43af-f22d-4589-a9a7-1d95955635c7",
      amount: 6295.82,
      name: "Biddy Stive",
      email: "bstivel@godaddy.com",
      dni: 36046068,
      provider_reference: 10489594587,
      date: "2020-02-04",
      status: "approved",
      payment_source: "batch_payment",
      payment_name: "master",
      payment_type: "credit_card",
      batch_id: 55,
    },
    {
      reference: "bf0077c7-af9e-4973-8a8e-32e920451eed",
      amount: 6934.36,
      name: "Arleen Arnaldy",
      email: "aarnaldym@fda.gov",
      dni: 33110108,
      provider_reference: 10520073083,
      date: "2020-01-02",
      status: "approved",
      payment_source: "batch_payment",
      payment_name: "amex",
      payment_type: "credit_card",
      batch_id: 55,
    },
    {
      reference: "1d582d01-6102-4dad-9eca-d6521df275cd",
      amount: 8531.81,
      name: "Sutherlan Bamlett",
      email: "sbamlettn@ucsd.edu",
      dni: 34684698,
      provider_reference: 10476973983,
      date: "2020-07-28",
      status: "approved",
      payment_source: "regular_payment",
      payment_name: "visa",
      payment_type: "credit_card",
      batch_id: 55,
    },
    {
      reference: "bda2eb1f-0768-479d-bfb2-3deb7f237966",
      amount: 5314.09,
      name: "Kellina Cammis",
      email: "kcammiso@wunderground.com",
      dni: 32191413,
      provider_reference: 10456765639,
      date: "2020-04-05",
      status: "approved",
      payment_source: "batch_payment",
      payment_name: "amex",
      payment_type: "credit_card",
      batch_id: 54,
    },
    {
      reference: "fd5b51f0-1bee-4136-97ad-46dd1c01ccd2",
      amount: 7787.11,
      name: "Muriel Tonkin",
      email: "mtonkinp@indiegogo.com",
      dni: 37887348,
      provider_reference: 10451395333,
      date: "2020-03-27",
      status: "approved",
      payment_source: "regular_payment",
      payment_name: "master",
      payment_type: "credit_card",
      batch_id: 55,
    },
    {
      reference: "69d4ca75-1ba2-4237-8ac1-3de7a068a4ac",
      amount: 2480.12,
      name: "Orella Scare",
      email: "oscareq@tripadvisor.com",
      dni: 37677266,
      provider_reference: 10472257024,
      date: "2019-12-29",
      status: "approved",
      payment_source: "regular_payment",
      payment_name: "master",
      payment_type: "credit_card",
      batch_id: 55,
    },
    {
      reference: "8aef7374-87d6-4c94-bd8c-a0d5f5d60316",
      amount: 6014.47,
      name: "Sylas Rhymer",
      email: "srhymerr@icio.us",
      dni: 30167185,
      provider_reference: 10458420899,
      date: "2019-11-17",
      status: "approved",
      payment_source: "regular_payment",
      payment_name: "amex",
      payment_type: "credit_card",
      batch_id: 55,
    },
    {
      reference: "2005baea-6439-4494-aba6-b68e4dbbdd86",
      amount: 5469.11,
      name: "Ulla Libbis",
      email: "ulibbiss@narod.ru",
      dni: 30755832,
      provider_reference: 10529193292,
      date: "2020-01-25",
      status: "approved",
      payment_source: "regular_payment",
      payment_name: "amex",
      payment_type: "credit_card",
      batch_id: 55,
    },
    {
      reference: "7312275c-18e0-4c5f-b0bb-d3b84417639b",
      amount: 3031.47,
      name: "Doralia Povlsen",
      email: "dpovlsent@flavors.me",
      dni: 30834567,
      provider_reference: 10486067807,
      date: "2020-07-09",
      status: "rejected",
      payment_source: "regular_payment",
      payment_name: "visa",
      payment_type: "credit_card",
      batch_id: 55,
    },
];
