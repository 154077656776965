import React, { useState } from "react";
import { Box, Heading, Flex, Text, Button, Stack, Image, useDisclosure, Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormControl,Textarea,
FormLabel, Select,
Input,
  ModalCloseButton,
  Divider, } from "@chakra-ui/core";
import { Link } from "react-router-dom";
const MenuItems = (props) => (
  <Link to={props.link}>
    <Button my={{ base: 2, md: 0 }} py={4} mx={1} color="#fffff" variant="link">
    {props.children}
    </Button>
  </Link>
);

const Header = (props) => {
  const [show, setShow] = useState(false);
  const [logged, setLogged] = useState(false);
  const handleToggle = () => setShow(!show);

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      padding="1.5rem"
      bg="white"
      color="gray.800"
     
      {...props}
    >
   
      <Flex align="center" mr={5}>
      <Image h={'27px'} src={require("../images/zap.svg")} pr={2} /> 
        <Heading as="h1" size="lg">
          Faraday
        </Heading>
        <Text fontSize="1.5rem" lineHeight="1.25">
          Hub
        </Text>
      </Flex>

      <Box display={{ sm: "block", md: "none" }} onClick={handleToggle}>
        <svg
          fill="white"
          width="12px"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
        </svg>
      </Box>

      <Box
        display={{ sm: show ? "block" : "none", md: "block" }}
        mt={{ base: 4, md: 0 }}
      >
        <Stack alignItems="center" justify="center" isInline>
          {logged && <MenuItems link="/activities">Actividad</MenuItems>}
          {logged && <MenuItems link="/clients">Clientes</MenuItems>}
          {logged && <MenuItems link="/batch">Batch</MenuItems>}
          <NewTicket >Ayuda</ NewTicket >
          <MenuItems link="/settings">Login</MenuItems>
          {!logged && (
            <Button
              _hover={{ bg: "#ebedf0" }}
              bg="transparent"
              border="1px"
              as="a"
              ml={4}
              href="https://youtu.be/49BzQuMSWvs"
              // onClick={() => setLogged(true)}
            >
              Prueba gratis
            </Button>
          )}
          {logged && (
            <Button
              _hover={{ bg: "#ebedf0" }}
              bg="transparent"
              border="1px"
              
              onClick={() => setLogged(false)}
            >
              Cerrar Sesión
            </Button>
          )}
        </Stack>
      </Box>
    </Flex>
  );
};

export default Header;


function NewTicket(props) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const initialRef = React.useRef()

  return (
    <>
      <Button onClick={onOpen} my={{ base: 2, md: 0 }} py={4} mx={1} color="#fffff" variant="link">
    {props.children}
    </Button>

      <Modal
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Si necesitas ayuda:</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Flex w="full" px={12} py={6} direction="column" justify="center">
            <Text mb={6} textAlign="center">Podés escribirnos  a:</Text>
            <Button
              _hover={{ bg: "#ebedf0" }}
              bg="transparent"
              border="1px"
              as="a"
              href="mailto:help@faradayhub.io"
              // onClick={() => setLogged(true)}
            >
              help@faradayhub.io
            </Button>
            </Flex>
            
            <Divider />
            <Text my={6} textAlign="center">o crear un nuevo ticket:</Text>
          <FormControl mt={4}>
              <FormLabel>Ticket ID</FormLabel>
              <Input value={uniqueID()} isDisabled="true" />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Nombre de empresa</FormLabel>
              <Input ref={initialRef} placeholder="First name" />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Urgencia</FormLabel>
              <Select size="md" placeholder="Seleccioná una opción">
  <option value="option1"> ℹ️ Baja</option>
  <option value="option2"> ⚠️ Media</option>
  <option value="option3"> 🚨 Alta</option>
</Select>
            </FormControl>

            

            <FormControl mt={4}>
              <FormLabel>Descripción</FormLabel>
              <Textarea placeholder="descripción del problema" />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3}>
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

function uniqueID(){
  function chr4(){
    return Math.random().toString(16).slice(-4);
  }
  return chr4() + chr4() +
    '-' + chr4() +
    '-' + chr4() +
    '-' + chr4() +
    '-' + chr4() + chr4() + chr4();
}