import React from "react";
import {
  Box,
  Heading,
  Flex,
  Text,
  Button,
  AspectRatioBox,
  Image,
  Grid,
  Divider,
  Input,
} from "@chakra-ui/core";

const MenuItems = ({ children }) => (
  <Text mt={{ base: 4, md: 0 }} mr={6} display="block">
    {children}
  </Text>
);

const Landing = (props) => {
  const [show, setShow] = React.useState(false);
  const handleToggle = () => setShow(!show);

  return (
    <>
      {/* {toHome ? <Redirect to="/home" /> : null} */}
      <Flex
        w="full"
        direction="column"
        minH="100vh"
        align="center"
        justify="flex-start"
        className={"access-back"}
        bg={"#dddddd"}
        color={"gray.800"}
      >
        <Box
          width={[
            "100%", // base
            "100%", // 480px upwards
            "100%", // 768px upwards
            "85%", // 992px upwards
          ]}
          color={"white"}
          bg={"gray.800"}
        >
          {/* Launch your next project faster with APIs and integration */}
          <Flex
            justifyContent="center"
            w="full"
            h="70vh"
            minH="550px"
            align="center"
            px={2}
            wrap="wrap"
          >
            <Box w={["100%", "100%", "50%"]} pl={[4, 6, 12]}>
              <Heading
                lineHeight={["2rem", "3rem", "3.5rem"]}
                py={6}
                fontWeight="300"
                fontSize={["2rem", "3rem", "3.5rem"]}
              >
                Una solución basada en la nube para negocios reales
              </Heading>
              <Text
                lineHeight={["3rem", "3rem", "2.5rem"]}
                pb={6}
                fontWeight="200"
                fontSize={["2rem", "3rem", "2.5rem"]}
              >
                <Box>Tu próxima integración </Box>
                <Box>con pagos más rápida</Box>
              </Text>
              <Text fontSize={"14px"} color="gray.500" mb={6}>
                {"cloud based | recurrencia | reportes | procesamiento online | soporte 24/7".toUpperCase()}{" "}
              </Text>

              <Button
                _hover={{ bg: "#ebedf0" }}
                colorScheme="teal"
                color={"gray.800"}
                onClick={() => console.log(false)}
              >
                VER DEMO
              </Button>
            </Box>
            <Box w={["100%", "100%", "50%"]} pb={4} pr={[4, 6, 12]}>
              <AspectRatioBox rounded="xl" maxW="800px" minW="300px" ratio={16 / 9}>
                <Image
                  p={3}
                  rounded="xl"
                  src={require("../images/faraexp.svg")}
                  alt="naruto"
                  objectFit="cover"
                />
              </AspectRatioBox>
            </Box>
          </Flex>
        </Box>
        <Box
          width={[
            "100%", // base
            "100%", // 480px upwards
            "100%", // 768px upwards
            "85%", // 992px upwards
          ]}
          bg="#fff"
        >
          <Flex
            justifyContent="center"
            alignItems="center"
            bg="#f0f0f0"
            px={[4, 12, 32]}
            py={12}
          >
            <ul class="container payment-container is-centered">
              <li class="paymentmethod-visa paymentmethod-large">Visa</li>
              <li class="paymentmethod-master paymentmethod-large">
                Mastercard
              </li>
              <li class="paymentmethod-amex paymentmethod-large">
                American Express
              </li>
              <li class="paymentmethod-mercadopago_cc paymentmethod-large">
                Mercado Pago + Banco Patagonia
              </li>
              <li class="paymentmethod-naranja paymentmethod-large">Naranja</li>
              <li class="paymentmethod-nativa paymentmethod-large">
                Nativa Mastercard
              </li>
              <li class="paymentmethod-tarshop paymentmethod-large">
                Tarjeta Shopping
              </li>
              <li class="paymentmethod-cencosud paymentmethod-large">
                Cencosud
              </li>
              <li class="paymentmethod-cabal paymentmethod-large">Cabal</li>
              <li class="paymentmethod-argencard paymentmethod-large">
                Argencard
              </li>
              <li class="paymentmethod-diners paymentmethod-large">Diners</li>
              <li class="paymentmethod-cordobesa paymentmethod-large">
                Cordobesa
              </li>
              <li class="paymentmethod-cmr paymentmethod-large">CMR</li>
              <li class="paymentmethod-patagonia365 paymentmethod-large">
                Patagonia365
              </li>
              <li class="paymentmethod-debvisa paymentmethod-large">debvisa</li>
              <li class="paymentmethod-debmaster paymentmethod-large">
                Patagonia365
              </li>
              <li class="paymentmethod-debcabal paymentmethod-large">
                debcabal
              </li>

              <li class="paymentmethod-cordial paymentmethod-large">Cordial</li>
              <li class="paymentmethod-banelco paymentmethod-large">Banelco</li>
              <li class="paymentmethod-bapropagos paymentmethod-large">
                Bapropago
              </li>
              <li class="paymentmethod-cargavirtual paymentmethod-large">
                Cargavirtual
              </li>
              <li class="paymentmethod-cobroexpress paymentmethod-large">
                cobroexpress
              </li>
              {/* <li class="payment-icon"> <%= image_tag 'cbu.png' %></li> */}

              <li class="paymentmethod-pagofacil paymentmethod-large">
                Pagofacil
              </li>
              <li class="paymentmethod-rapipago paymentmethod-large">
                Rapipago
              </li>
              <li class="paymentmethod-redlink paymentmethod-large">Redlink</li>
              <li class="paymentmethod-bitcoin paymentmethod-large">Bitcoin</li>
              {/* <li class="payment-icon"> <%= image_tag 'ethereum.png' %></li> */}
              <li class="paymentmethod-account_money paymentmethod-large">
                account_money
              </li>
            </ul>
          </Flex>
          <Box py={12}>
            <Heading
              fontWeight="400"
              fontSize={["2rem", "2rem", "2.5rem"]}
              pb={12}
              px={6}
              textAlign="center"
            >
              <Box>Los negocio son complejos,</Box>
              <Box>FaradayHub simplifica una parte</Box>
            </Heading>
            <Flex justifyContent="center" w="full" align="center" px={2} wrap="wrap" alignItems="flex-start">
              <PointBox
                title="Muchos medios de pagos?"
                description="FaradayHub soporta todos, te ayudamos a hacerle la vida más fácil a tus clientes"
                image={require("../images/1a.svg")}
              />

              <PointBox
                title="Diferentes cuentas?"
                description="Acá vas a poder centralizar todas las cuentas de tus proveedores de pago"
                image={require("../images/2a.svg")}
              />

              <PointBox
                title="Nuevas regulaciones?"
                description="Nosotros nos encargamos de las regulaciones, la seguridad y de los nuevos métodos de pagos"
                image={require("../images/3a.svg")}
              />
            </Flex>
          </Box>

          <Box w="full" bg="#f0f0f0">
            <Heading pt={12} textAlign="center" size="lg" fontWeight="300">
              Nueva integración, pero 3x más rápido y flexible
            </Heading>
            <Heading textAlign="center">Servicios</Heading>
            <Grid
              w="full"
              templateColumns={[
                "repeat(1, 1fr)",
                "repeat(2, 1fr)",
                "repeat(3, 1fr)",
              ]}
              gap={6}
              py={12}
              px={6}
            >
              <SquareBox
                text="Cobros Online"
                image={require("../images/1.svg")}
              />
              <SquareBox
                text="Pagos Recurrentes"
                image={require("../images/2.svg")}
              />
              <SquareBox
                text="Reportes Realtime"
                image={require("../images/3.svg")}
              />
              <SquareBox
                text="Seguridad y Backups"
                image={require("../images/4.svg")}
              />
              <SquareBox
                text="Múltiples Integraciones"
                image={require("../images/5.svg")}
              />
              <SquareBox
                text="Soporte Personalizado"
                image={require("../images/6.svg")}
              />
            </Grid>
          </Box>
        </Box>

        <Flex
          w="full"
          direction="column"
          justifyContent="center"
          alignItems="center"
          bg="white"
          pt={12}
        >
          <Heading textAlign="center">Escribinos</Heading>
          <Button
            mt={2}
            as="a"
            leftIcon="email"
            variantColor="teal"
            variant="solid"
            href="mailto:bruno@faradayhub.io"
          >
            Enviar email!
          </Button>
        </Flex>
      </Flex>
    </>
  );
};

export default Landing;

function SquareBox(props) {
  return (
    <Flex
      w="full"
      align="center"
      justify="center"
      direction="column"
      boxShadow={"5px 5px 16px 0 rgba(0,0,0,0.09)"}
      bg="white"
      p={2}
    >
      <AspectRatioBox rounded="md" w="full" maxW="400px" ratio={16 / 9}>
        <Image
          rounded="md"
          src={props.image}
          alt={props.image}
          objectFit="cover"
        />
      </AspectRatioBox>

      <Text
        fontSize="22px"
        pb={2}
        fontWeight="bold"
        textAlign="center"
        pt={4}
        color="gray.700"
      >
        {props.text}
      </Text>
    </Flex>
  );
}

function PointBox(props) {
  return (
    <Flex
      w={["full", "full", '30%']}
      align="center"
      justify="center"
      direction="column"
      py={2}
      px={[4, 12, 24]}
    >
      <Image h="120px" src={props.image} alt={props.image} objectFit="cover" />

      <Text
        fontSize="22px"
        pb={2}
        fontWeight="bold"
        textAlign="center"
        pt={4}
        color="gray.700"
      >
        {props.title}
      </Text>
      <Text
        fontSize="18px"
        pb={2}
        fontWeight="300"
        textAlign="center"
        color="gray.700"
      >
        {props.description}
      </Text>
    </Flex>
  );
}
